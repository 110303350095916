import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Legal = lazy(() => import('sections/hero/Legal.js'));
const AvisoLegal = lazy(() => import('sections/services/AvisoLegal.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Aviso Legal: Información importante</title>
          <meta name="description" content="Encuentra aquí nuestro Aviso Legal. Información relevante sobre el uso de este sitio web y tus derechos como usuario ante VASCO Solutions." />
        </Helmet>
        <Layout>
          <Legal />          
          <AvisoLegal />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`